import { AirTrackItem } from '../types/air_types';
import { TrackItem, Credentials, UrlParams } from '../types/types';
import { Dispatch, SetStateAction } from 'react';

const API_BASE_URL = 'https://cargo.nova-tms.com/api/v1';


const getParamsFromUrl = async (): Promise<UrlParams> =>{
   
    let query = new URLSearchParams(window.location.search);

    let session_id = query.get('session_id');
    let number = query.get('number');
    let instance_name = query.get('name');
    let reference_id = query.get('reference_id');
    let monitoring_status = query.get('monitoring_status');

    let language = query.get('lang');
    let tmsLanguage = language ? language : "en";  

    let type = query.get('type');

    let urlParams: UrlParams ={
      session_id: session_id,
      number: number,
      instance_name: instance_name,
      language: tmsLanguage,
      reference_id: reference_id,
      monitoring_status: monitoring_status,
      monitoring_type: type  
    }
  
      return urlParams
}

const fetchWithAuth = async (url: string, method: string, token: string, body?: any) => {
    const options: RequestInit = {
      method,
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    };

    if (body) {
      options.body = JSON.stringify(body);
    }
  
    const response = await fetch(url, options);
  
    if (!response.ok) {
      throw new Error(`Network response was not ok: ${response.statusText}`);
    }
  
    return response.json();
}


const handleRefreshToken = async ( refresh:string , token: string  ) => {
    const url = `${API_BASE_URL}/token/refresh/`; 
      
    const payload = { refresh: refresh };

    try {
      const data = await fetchWithAuth(url, 'POST', token, payload);
      return data.access;
    } catch (error) {
      console.error('Error refreshing token:', error);
      throw error;
    }

  };


const handleGetToken = async (user:string, password: string ) => {

    const url = `${API_BASE_URL}/token/`;
      
    const payload = { 
        username: user,
        password: password
    };

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });
  
      if (!response.ok) {
        throw new Error('Failed to obtain tokens');
      }
  
      return response.json();
    } catch (error) {
      console.error('Error getting token:', error);
      throw error;
    }
  };


  const handleGetTrackingItem = async (session_id:string, number: string ,refreshedData:string , reference_id:string | null , monitoring_type: string | null) => {
     

   try { 
    let url: string ;

    if(monitoring_type === 'SEA'){
      
       url =`${API_BASE_URL}/container/tracking/?session_id=${session_id}&number=${number}` + (reference_id ? `&reference_id=${reference_id}` : ''); //temporary if tracking session == null
    }else if(monitoring_type === 'AIR'){

       url =`${API_BASE_URL}/air/tracking/?session_id=${session_id}&number=${number}` + (reference_id ? `&reference_id=${reference_id}` : ''); 
    }else{
       throw new Error("Invalid transport type provided");
    }
      const data = await fetchWithAuth(url, 'GET', refreshedData);

      if (data.results && data.results.length > 0) {
               
        if (monitoring_type === 'SEA') {
          const result = (data.results as TrackItem[]).find(item => item.number === number);
          if (result) {
            return result;
          } else {
            throw new Error('No matching SEA tracking item found.');
          }

        } else if (monitoring_type === 'AIR') {
          const result = (data.results as AirTrackItem[]).find(item => item.number === number);

          if (result) {
            return result;
          } else {
            throw new Error('No matching AIR tracking item found.');
          }
        } else {
          throw new Error('Invalid monitoring type.');
        }

      } else {
        throw new Error('auth_no_tracking_id_found_in_the_response');
      }
  } catch (error) {
      console.error('Error getting tracking ID:', error);
      throw error;
  }
      
  };


   
  const handleTokenRefreshAndTracking = async (
    credentials: Credentials,
    setTrackItem: Dispatch<SetStateAction<TrackItem | AirTrackItem| null>>, 
    setLoading: Dispatch<SetStateAction<boolean>>, 
    setError: Dispatch<SetStateAction<string>>,
    setLanguage: Dispatch<SetStateAction<string | null>>,
    monitoring_type: string | null,
    ) => {
     const {user , password , session_id , number, language , reference_id } = credentials
    try {
        setLoading(true);
        setError('');

        const getToken = await handleGetToken(user, password);
        if (!getToken) {
            setError('auth_failed_to_get_token');
            setLoading(false);
            throw new Error('auth_failed_to_get_the_token');
        }

        const refreshedData = await handleRefreshToken(getToken.refresh, getToken.access);
        if (!refreshedData) {
            throw new Error('auth_the_token_has_not_been_updated');
        }
        
        const trackingItem = await handleGetTrackingItem(session_id, number, refreshedData , reference_id, monitoring_type);
        setTrackItem(trackingItem);
        setLanguage(language);
    } catch (error) {
        setError('auth_error_occured_please_try_again');
    } finally {
        setLoading(false);
    }
};
 

export{
    handleRefreshToken,
    handleTokenRefreshAndTracking,
    handleGetToken,
    getParamsFromUrl
}