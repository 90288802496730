import { Event} from '../../types/types';
import { Grid } from '@mui/material'
import EventStatus from './EventStatus'
import EventDate from './EventDate';
import {AirTimeLineEvent } from '../../types/air_types';
import {Typography} from '@mui/material';
import { useTranslation } from 'react-i18next';


const VerticalTimeLineEvent: React.FC<{event: Event | AirTimeLineEvent | null }> = ({event}) =>{

  const {t} = useTranslation();
   
  if (!event) return null;

  if('flight_number' in event){
     return (
        <Grid container justifyContent="space-between" alignItems="baseline">
             <Grid>
                <Typography variant="body1" fontSize={12} component="span">
                 { event.event_code && t(event.event_code) } , 
                  {/* {event.description} */}
                  {event?.piece &&  event.piece + t('pcs')}  / {event.weight && event.weight + "kg "}
                  {event?.flight_number &&  t("Flight") +" " +" Nr: " + event.flight_number}
               </Typography>
              </Grid>
              <Grid>
                 <EventDate date ={event.sortDate}  />
              </Grid>
        </Grid>
     )

  }
  
  if('vessel' in event) {
    return(
      <Grid container justifyContent="space-between" alignItems="baseline" >
          <Grid item >
              <EventStatus status={event.status} />
          </Grid>
          <Grid item  >
              <EventDate date={event.date} />
          </Grid>
      </Grid>
    )
  }

  return <></>
  



 
}


export default VerticalTimeLineEvent;