import LanguageSelector from "./LanguageSelector";
import { useTranslation } from 'react-i18next';
import {Box, Typography} from '@mui/material';
import { MonitoringStatuses } from "../types/types";
import { InfoSideBox } from "../styles/InfoSideBarStyles";


const InfoSideBarFooter: React.FC<{language: string | null ,monitoring_status: MonitoringStatuses }> = ({language,monitoring_status}) =>{
    const { t } = useTranslation();
     return(
        <Box sx={{paddingTop:3}}>    
        <InfoSideBox>
              <Box display="flex"> 
                  <LanguageSelector tmslanguage = {language}/>
              </Box>
              <Typography variant="subtitle2"> {t('info_side_monitoring')}: { t(monitoring_status + "_monit_status" )}</Typography> 
        </InfoSideBox>
       </Box> 
     )
}


export default InfoSideBarFooter;