import {Cordinate} from '../../types/types';
import {Marker, CircleMarker} from 'react-leaflet';
import { CustomMapIcon} from '../../styles/MapViewerStyles';
import { useTheme } from '@mui/material/styles';

const PinMarker: React.FC<{pin: Cordinate , radius: number}> = ({pin , radius}) =>{

    const theme = useTheme();

     return(
        <>
            <Marker position={pin} icon={CustomMapIcon(theme.palette.secondary.main)} />
            <CircleMarker
                    center={pin}
                    radius={radius}
                    color={theme.palette.secondary.light} 
                    fillColor={theme.palette.secondary.light} 
                    fillOpacity={0.5} 
                />
        </>
     )

}


export default PinMarker;

