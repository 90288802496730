import { Tooltip } from 'react-leaflet';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { AirRoute, TravelTimes } from '../../types/air_types';



const PolyLineTooltip: React.FC<{travel_times: TravelTimes, route: AirRoute  }> = ({travel_times , route})=> {
   

      let travel_time: string | null = null;

        const matchedSegment = travel_times.segmentTimes.find(
          item => item.order_id === route.order_id
        );
        if (matchedSegment && matchedSegment.travelTime ) {
          const hours = Math.floor(matchedSegment.travelTime / 60);
          const minutes = matchedSegment.travelTime % 60;
             travel_time =  `${hours} h : ${minutes} min`;
        } else {
            travel_time = null;
        }
    
                    
        return travel_time ? (
            <Tooltip permanent direction="center" offset={[0, -20]} className="custom-tooltip">
              <AccessTimeIcon sx={{ fontSize: 13 }} />: {travel_time}
            </Tooltip>
          ) : null;
     

}

export default PolyLineTooltip;