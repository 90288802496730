import { MonitoringStatuses, TrackItem } from '../types/types';
import SeaInfoSideBar from "./Sea/SeaInfoSideBar";
import MapViewer from "./Map/MapViewer";
import { useTranslation } from 'react-i18next';
import ErrorBox from './ErrorBox';
import { AirTrackItem } from '../types/air_types';
import { isTrackAirItem, isTrackItem } from '../helpers/trackItemsUtils';
import AirInfoSideBar from './Air/AirInfoSideBar';
import SharedGrid from './SharedGrid';


interface CommonPropBase {
   language: string | null,
   monitoring_status: MonitoringStatuses,
   trackItem: TrackItem | AirTrackItem,

}

const GridItems: React.FC<CommonPropBase> = ({trackItem , language , monitoring_status}) =>{
  const { t } = useTranslation();
    
 if(isTrackItem(trackItem)){
    
   const  {data, message } = trackItem.raw;

    if(message !== 'OK'){
       
      let error = t("auth_error_fetching_data");
        return <ErrorBox error={error} />;
    }

    return (
        <SharedGrid 
           sideBar = {<SeaInfoSideBar container_data={data}  language = {language} monitoring_status = {monitoring_status}/>}
           mapViewer = {<MapViewer locations={data.locations}  route_data={data.route_data}/>}
          
        />
    )
 }


 if(isTrackAirItem(trackItem)){
        const {status_code ,metadata, data} = trackItem.raw;

        if(status_code !== 'OK'){
            let error = t("auth_error_fetching_data");
            return <ErrorBox error={error} />;
        }

        let routes = data?.routes ? [...data.routes ] : [];

        return(
            <>
                {data !== null ?
                    <SharedGrid 
                      sideBar = {<AirInfoSideBar air_data={data}  metadata={metadata} language = {language} monitoring_status = {monitoring_status} />}  
                      mapViewer = {<MapViewer route_data = {routes}  locations ={null}  />} />
                    :
                    <ErrorBox error={t("No air info")} />
                }
            </>
            
        )

 }

 let error = t("no_track_item");
 return <ErrorBox error={error} />;
       
}


export default GridItems