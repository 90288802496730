import {Box, Typography} from '@mui/material'
import RouteDetails from '../HorizontalTimeLine/RouteDetails';
import SeaTabs from './SeaTabs';
import { InfoSideMainBox, InfoSidePaper,InfoPaper,InfoSideBox,ChipStatus } from '../../styles/InfoSideBarStyles';
import { Data , ContainerStatuses, Container, ObjectTypes,VesselWithEvents, LocationsWithEvents, MonitoringStatuses } from '../../types/types';
import { getLocationsWithEvents } from '../../helpers/eventHelpers';
import { getVesselsWithEvents } from "../../helpers/vesselHelper";
import { getEnumValue , getContainerType} from '../../helpers/infoSideBarHelper';
import { useTranslation } from 'react-i18next';
import InfoSideBarFooter from '../InfoSideBarFooter';



const SeaInfoSideBar: React.FC<{container_data: Data , language: string | null , monitoring_status: MonitoringStatuses}> = ({container_data , language, monitoring_status })=>{

     const { t } = useTranslation();
     const { vessels , containers, locations,route , metadata } = container_data;
     
     let object_type = getEnumValue(metadata.type, ObjectTypes );    //get object type 
     let status = getEnumValue(metadata.status, ContainerStatuses);  //get container status
     let containerType = getContainerType(containers,metadata.number); //get container type

    let vessels_with_events : VesselWithEvents[] = [];
    let locations_with_events : LocationsWithEvents[] = [];

    const containerData: Container | undefined = containers.find(c => c.number === metadata.number );

     if(containerData?.events !== null){
           locations_with_events = getLocationsWithEvents(locations,containerData?.events); 
     }
      
    if(vessels !== null && containerData?.events !== null ){
          vessels_with_events = getVesselsWithEvents(vessels,containerData?.events)
    }

    let size_type : string | null = containerData?.size_type ?? null;

    return(
        <InfoSideMainBox>
            <InfoSidePaper>
                <InfoPaper elevation={2} >
                     <Typography variant='h6' >{metadata.number}</Typography> 
                </InfoPaper>
                 
                 <Box sx={{paddingTop:3}}>
                     <InfoSideBox>
                            <Box display="flex">
                                <Typography variant="subtitle2" sx={{ mr: 1 }}> {object_type} </Typography> 
                                <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>{metadata.number}</Typography>
                            </Box>
                            <Typography variant="subtitle2" sx={{ mr: 1 }}>
                                    1 x {  size_type ? size_type : t(containerType)}
                                <ChipStatus  size="small"  label={t(status)} />          
                            </Typography>
                     </InfoSideBox>     
                 </Box>

                 <RouteDetails routes={route} locations = {locations} sea_line_name= {metadata.sealine_name}/>

                <SeaTabs locations_with_events={locations_with_events} vessels_with_events={vessels_with_events} locations={locations} /> 

               <InfoSideBarFooter language={language} monitoring_status={monitoring_status}/>
                 

            </InfoSidePaper>    
         </InfoSideMainBox>
    )
}



export default  SeaInfoSideBar