import { StepIconProps } from '@mui/material/StepIcon';
import TripOriginIcon from '@mui/icons-material/TripOrigin';
import ArrowCircleDownOutlinedIcon from '@mui/icons-material/ArrowCircleDownOutlined';
import { TimeLineStepIcon} from '../../styles/TimeLineStyles';

interface ExtendedStepIconProps extends StepIconProps {
    custom_icon?: string;
 }


function VerticalLineStepIcon(props: ExtendedStepIconProps) {
    const { active, completed, className , custom_icon} = props;
      
    let iconColor: 'inherit' | 'primary' | 'secondary' | 'disabled' = 'inherit';
  
     if(!active && !completed ){
           
      iconColor = 'disabled';
     }else{
      iconColor = 'secondary';
     }
     
  
    return (
      <TimeLineStepIcon ownerState={{ completed, active }} className={className}>
       {custom_icon == 'location' ? <TripOriginIcon  sx={{fontSize: 18}} color={iconColor}  /> : <ArrowCircleDownOutlinedIcon  sx={{fontSize: 18}} color={iconColor}  />   } 
      </TimeLineStepIcon>
    );
  }


export default VerticalLineStepIcon