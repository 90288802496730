import { DateTimeLocal, AirLine } from "../../types/air_types";
import { InfoSideBox } from '../../styles/InfoSideBarStyles';
import {Box, Typography } from '@mui/material';
import dayjs from 'dayjs';
import 'dayjs/locale/pl';
import i18n from '../../helpers/i18n';
import { useTranslation } from 'react-i18next';



const AirRouteInfo: React.FC<{departure: DateTimeLocal | null , arrival: DateTimeLocal | null , airline: AirLine | null }> = ({departure,arrival, airline}) =>{
    
   const {t} = useTranslation();
    const currentLocale = i18n.language;
    dayjs.locale(currentLocale);
   

    const formatDate = (date: string | undefined, prefix: string) => {
      if (!date) return t('route_invalid_date');
      const isValidDate = dayjs(date).isValid();
      const formattedDate = isValidDate ? dayjs(date).format('DD MMM YYYY HH:mm') : t('route_invalid_date');
      return `${prefix}: ${formattedDate}`;
    };

    let departureTime = departure
    ? departure.actual
      ? formatDate(departure.actual, 'ATD')
      : departure.estimated
      ? formatDate(departure.estimated, 'ETD')
      : t('route_invalid_date')
    : t('route_invalid_date');


    let arrivalTime = arrival
    ? arrival.actual
      ? formatDate(arrival.actual, 'ATA')
      : arrival.estimated
      ? formatDate(arrival.estimated, 'ETA')
      : t('route_invalid_date')
    : t('route_invalid_date');


    let airline_name = airline?.name;

   return(
       <InfoSideBox sx={{ paddingTop:1 }} >
           <Box display = "flex">
              <Typography variant="body1" fontSize={13}>{departureTime}</Typography>
              <Typography  variant="body1" fontSize={13} sx={{paddingLeft: 2}}> {arrivalTime}</Typography>
            </Box>
              <Box>
              {
                 airline_name ? 
                    <Typography   variant="body1" fontSize={13} sx={{paddingRight: 1}} >
                          {airline_name}
                    </Typography>   
                   :
                   ''
              }
              </Box>   
           
       </InfoSideBox>
   )
     
}


export default AirRouteInfo;