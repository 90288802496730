
import { Containers, Container , ContainerTypes, MonitoringStatuses,} from '../types/types';


const getEnumValue = <T extends { [key: string]: string }>(value: string | null, enumObj: T): T[keyof T] | string => {

        const enumKeys = Object.keys(enumObj) as (keyof T)[];
        const matchingKey = enumKeys.find(key => key === value);
    
    if (matchingKey !== undefined) {
      return enumObj[matchingKey];
    }
  
    return "status_unknown";
  };
  
const findContainerByNumber = (containers: Containers, containerNumber: string): Container | undefined => {
    return containers.find(container => container.number === containerNumber);
  };
 


const getContainerType = (containers: Containers, containerNumber: string ): ContainerTypes | string =>{
    
  const container = findContainerByNumber(containers, containerNumber);   //Get Container from containers by number

  let containerType: string = '';


  if(container){
    return containerType = getEnumValue(container.iso_code, ContainerTypes)
  }
  else{
    return containerType = '';
  }
  };

  const setMonitoringStatus = (status: string | null) : MonitoringStatuses => {
    let monitoring_status_from_tms: MonitoringStatuses ;
    
    if (status === 'CP' || status === 'IN' || status === 'DL') {
      return  monitoring_status_from_tms = status; 
    } else {
      return  monitoring_status_from_tms = 'UNKN'; 
    }
  }


const a11yProps = (index: number) => {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`
    }
 }

 export{ 
     getEnumValue,
     findContainerByNumber,
     getContainerType,
     setMonitoringStatus,
     a11yProps
 }
