
import { RoutePoint,Location,RouteData,SortedRoutePoint } from '../types/types';


const getSortedUniqueRoutes = (routeData: RouteData): RoutePoint[] => {
     
    let routeArray: RoutePoint[] = Object.values(routeData).filter(route => route.date && route.location);


    return routeArray.reduce((acc: RoutePoint[], current: RoutePoint) => {
      const existingIndex = acc.findIndex(item => item.location === current.location);
  
      if (existingIndex !== -1) {
        if (new Date(current.date ?? "") > new Date(acc[existingIndex].date ?? "")) {
          acc[existingIndex] = current;
        }
      } else {
        acc.push(current);
      }
  
      acc.sort((a, b) => new Date(a.date ?? "").getTime() - new Date(b.date ?? "").getTime());
  
      return acc;
    }, []);
  };


  const addLocationData = (routePoints: RoutePoint[], locations: Location[]): SortedRoutePoint[] => {
    return routePoints.map(routePoint => {
      const locationData = locations.find(location => location.id === routePoint.location) || null;
      return { ...routePoint, locationData: locationData };
    });
  };


  const getActiveStep = (sortedRoutes: RoutePoint[]): number => {

    if (sortedRoutes.length === 2) {
          if (sortedRoutes[0].actual === true && sortedRoutes[1].actual === true) {
            return 1; 
          }
          if (sortedRoutes[0].actual === true && sortedRoutes[1].actual === false) {
            return 0; 
          }
          return -1; 
          }

    const indexOfFirstFalse = sortedRoutes.findIndex(route => route.actual === false);

        if (indexOfFirstFalse !== -1) {
            
          return indexOfFirstFalse > 0 ? indexOfFirstFalse - 1 : 0;

        } else {
          
          return sortedRoutes.length - 1;
        }
  };



export {
    getSortedUniqueRoutes,
    addLocationData,
    getActiveStep,

}