import React,{Fragment} from 'react';
import type {VesselWithEvents, Locations} from '../../types/types';
import { Typography } from '@mui/material';
import { Grid ,Divider} from '@mui/material';
import { formatDate } from '../../helpers/vesselHelper';
import { BoldTypography, PaperInfoNoData } from '../../styles/InfoSideBarStyles';
import { useTranslation } from 'react-i18next';

const VesselComponent: React.FC<{vessels : VesselWithEvents[] , locations: Locations}> = ({vessels , locations}) =>{

    const { t } = useTranslation();

    return(    
        <>

                { vessels && vessels.length > 0 ? vessels.map((vessel) => (
                    <Fragment key={vessel.vessel.mmsi}>
                        <Grid container 
                                spacing={4}
                                justifyContent="flex-start"
                                alignItems="baseline"   >
                                <Grid item >
                                    <BoldTypography variant="subtitle2"> {t("vessel")}: </BoldTypography>  
                                    <BoldTypography variant="subtitle2"> {t("vessel_vouage")}: </BoldTypography>  
                                    <BoldTypography variant="subtitle2"> {t("vessel_loading")}:  </BoldTypography>
                                    <BoldTypography variant="subtitle2"> {t("vessel_discharge")}: </BoldTypography>
                                    { vessel.events.length > 0 ? 
                                        <>
                                            <BoldTypography variant="subtitle2"> {vessel?.events[0]?.actual == true ? "ATD:" : "ETD:" }   </BoldTypography>
                                            <BoldTypography variant="subtitle2"> {vessel?.events[1]?.actual == true ? "ATA:" : "ETA:" }  </BoldTypography>  
                                        </> 
                                      : 
                                       null 
                                    }
                                </Grid>
                                <Grid item  >
                                    <Typography variant="subtitle2">  {vessel.vessel.name}  </Typography>  
                                    { vessel.events.length > 0 ? 
                                        <>
                                            <Typography variant="subtitle2"> {vessel?.events[0]?.voyage ?? t("no_data")}</Typography>  
                                            <Typography variant="subtitle2">{locations.find(loc => loc.id == vessel?.events[0]?.location)?.name ?? t("no_data")} </Typography>
                                            <Typography variant="subtitle2"> {locations.find(loc => loc.id == vessel?.events[1]?.location)?.name ?? t("no_data") } </Typography>
                                            <Typography variant="subtitle2">  { vessel?.events[0]?.date ? formatDate(vessel.events[0].date) : t("unknown_date") } </Typography>
                                            <Typography variant="subtitle2">  { vessel?.events[1]?.date ? formatDate(vessel.events[1].date) : t("unknown_date")} </Typography>  
                                        </>
                                        :
                                        <PaperInfoNoData elevation={1} > {t("No full information about vessel")} </PaperInfoNoData>
                                     }
                                </Grid>
                        </Grid>
                        <Divider variant="middle" sx={{ my: 2 }} />
                    </Fragment>
                ))
                : 
                <PaperInfoNoData elevation={1} > {t("vessels_no_information_about_vessels")} </PaperInfoNoData>
            }  
        </>
    )
}


export default VesselComponent