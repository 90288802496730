import React from 'react';
import { AirRoute } from '../../types/air_types';
import { Polyline} from 'react-leaflet';
import { Marker } from 'react-leaflet';
import { useTheme } from '@mui/material/styles';
import { calculateTravelTimes } from '../../helpers/airHelper';
import { CustomMapMarker } from '../../styles/MapViewerStyles';
import MapPointTooltip from './MapPointTooltip';
import PolyLineTooltip from './PolyLineTooltip';


const AirMapViewer: React.FC<{air_routes: AirRoute[] | null}> =({air_routes}) => {
    const theme = useTheme();


    let active_color = theme.palette.secondary.dark;
    let estimated_color = theme.palette.primary.light;

    let travelTimes = calculateTravelTimes(air_routes);

    return (
      <>
            { air_routes !== null &&  air_routes.map((route, index) => {

              const nextRoute = air_routes[index + 1];
             
              return(
                <div key={index}>
                       
                    {(route.arrival_datetime_local.actual || ( nextRoute?.arrival_datetime_local.actual) ) ?  
                      (<Polyline positions={route.path} color={active_color} > 
                          <PolyLineTooltip travel_times={travelTimes}  route = {route}  />
                      </Polyline> ) 
                      :
                      (<Polyline positions={route.path}  color={estimated_color} dashArray="3 4">
                          <PolyLineTooltip travel_times={travelTimes}  route = {route}  />
                        </Polyline>

                      ) 
                    }
                     
                    
                    
                    {/* Marker for  point*/}
                  { (route?.origin?.lat && route?.origin?.lng && route?.origin?.lat !== 0 && route?.origin?.lng !== 0 ) && (
                      <Marker
                          key={index}  
                          position={[route?.origin?.lat , route?.origin?.lng]}
                          icon={CustomMapMarker( route.departure_datetime_local.actual ? active_color : estimated_color  )}   
                          >
                          <MapPointTooltip travel_times={travelTimes}  route = {route}  isLast = {false} />     
                       </Marker>
                  )}


                    {/* Marker for end last point to */}
                    {index === air_routes.length - 1 && route?.destination?.lat && route?.destination?.lng && route?.destination?.lat !== 0 && route?.destination?.lng !== 0 && (
                        <Marker
                          position={[route?.destination?.lat, route?.destination?.lng]}
                          icon={CustomMapMarker(route.arrival_datetime_local.actual ? active_color : estimated_color)}
                        >
                          <MapPointTooltip travel_times={travelTimes} route={route} isLast={true} />
                        </Marker>
                     )}      
                    
                </div>
              )
           })}
       </>
    );
};


export default AirMapViewer;