import { Theme } from '@mui/material/styles';
import { styled } from '@mui/material/styles';
import {Box, Paper , Chip, Typography} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';



const InfoSideMainBox = styled(Box)(({ theme }) => ({
    height: '100%',
    backgroundColor: 'white',
    color: theme.palette.text.primary,
    padding: 10,
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 2
}));

const InfoSideBox = styled(Box)(({theme})=>({
    display: 'flex', 
    justifyContent: 'space-between', 
    alignItems: 'center'

}));

const LoaderBox = styled(Box)(({theme})=>({
    display: 'flex',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    position: 'absolute',
    justifyContent: 'center',
    alignItems: 'center',
}));



const InfoSidePaper = styled(Paper)(({theme})=>({
    padding: 12, 
    bgcolor: 'white', 
    height: '100%' 
}))


const InfoPaper = styled(Paper)((theme)=>({
    height: '50px',
    verticalAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'left',
    padding: 12,
    marginBottom: '10px'
}))


const ChipStatus = styled(Chip)(({theme})=>({
    backgroundColor: theme.palette.secondary.light, 
    color: theme.palette.secondary.main, 
    marginLeft: '4px',
}))

const BoldTypography = styled(Typography)(({ theme }) => ({
    fontWeight: 'bold',
  }))


const Loader = styled(CircularProgress)(({ theme }) => ({
    color: theme.palette.secondary.main,
  }))


const PaperInfoNoData = styled(Paper)(({ theme }) => ({
    textAlign: 'center',
    color: theme.palette.text.secondary,
    height: 60,
    lineHeight: '60px',
    padding: "3px",
  }));


export { 
    InfoSideMainBox, 
    InfoSideBox, 
    InfoSidePaper, 
    InfoPaper, 
    ChipStatus,
    BoldTypography,
    Loader,
    LoaderBox,
    PaperInfoNoData
};






