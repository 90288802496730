import React from 'react';
import {RouteData, Locations,SortedRoutePoint} from '../../types/types';
import { useState, useEffect } from 'react';
import { getSortedUniqueRoutes , addLocationData , getActiveStep  } from '../../helpers/routeHelper';
import HorizontalTimeLine from './HorizontalTimeLine';
import RouteInfo from './RouteInfo';




const RouteDetails: React.FC<{routes: RouteData , locations: Locations, sea_line_name: string | null }> = ({routes,locations,sea_line_name}) =>{
     
    const [routePoints, setRoutesPoints] = useState<SortedRoutePoint[] | []>([]);
    const [activeStep , setActiveStep] = useState<number>(0);


     useEffect(()=>{

        const sortedUniqueRoutes = getSortedUniqueRoutes(routes);
        const sortedRoutesWithLocations = addLocationData(sortedUniqueRoutes, locations);
        setRoutesPoints(sortedRoutesWithLocations);

        const activeStep = getActiveStep(sortedRoutesWithLocations);
        setActiveStep(activeStep);


     },[routes , locations ])
       
    return (
        <>
            <HorizontalTimeLine routePoints={routePoints}  activeStep={activeStep} />
            <RouteInfo  routePoints={routePoints} sea_line_name={sea_line_name}  />

         </>
    )


}

export default RouteDetails;

