import Home from './pages/Home';
import { TransportTypeProvider } from './context/TransportTypeContext';

function App() {
  return (
     <>
      <TransportTypeProvider>
            <Home />
       </TransportTypeProvider> 
     </>
  );
}

export default App;
