import { TrackItem} from "../types/types";
import { AirTrackItem } from "../types/air_types";

function isTrackItem(item: TrackItem | AirTrackItem | null): item is TrackItem {
    return !!item && 'message' in item.raw;
}

function isTrackAirItem(item: TrackItem | AirTrackItem | null): item is AirTrackItem {
    return !!item && 'success' in item.raw;
}

export {
    isTrackItem,
    isTrackAirItem
}
