import { AirRoute,DateTimeLocal, TravelTimes } from "../../types/air_types";
import { useTranslation } from "react-i18next";
import { PaperInfoNoData, BoldTypography } from "../../styles/InfoSideBarStyles";
import { Grid, Divider, Typography} from '@mui/material';
import { Fragment } from "react/jsx-runtime";
import { formatDateAndTime } from '../../helpers/vesselHelper';
import { calculateTravelTimes } from "../../helpers/airHelper";
import { convertToTime } from "../../helpers/airHelper";
import { SegmentTime } from "../../types/air_types";
import React from "react";

interface AirRouteWithSegment extends AirRoute{
      segmentTimes: SegmentTime | null 
}

const RenderTimeLabel: React.FC<{datetime_local:DateTimeLocal, type: number } > = ({ datetime_local , type}) => {
    // type 1 departure
    // type2 arrival 
   
   if(datetime_local){

     return( 
      <BoldTypography  variant="subtitle2">
             { type === 1 ?  (datetime_local.actual ? "ATD" : "ETD") :  (datetime_local.actual ? "ATA" : "ETA") }
      </BoldTypography> 
     )
   }else{
       return null;
   }

 };

 const RenderTime: React.FC<{datetime_local:DateTimeLocal}> = ({datetime_local}) =>{

   const { t } = useTranslation();
   return(
   <BoldTypography  variant="subtitle2">
         {datetime_local.actual ? 
               formatDateAndTime(datetime_local.actual) : 
               ( datetime_local.estimated ? 
                  formatDateAndTime(datetime_local.estimated) : t("unknown_date"))
            
         }
    </BoldTypography>
   )
 }
 
 const RenderSegmentTime: React.FC<{segmentTime: number | undefined ,label: string }> = ({segmentTime , label}) => {
   if (segmentTime !== undefined && segmentTime > 0) {
     return <BoldTypography variant="subtitle2">{label}:</BoldTypography>;
   }
   return null;
 };
 

 const renderFormattedTime: React.FC<{time: number | undefined }> = ({time}) => {
   if (time !== undefined && time > 0) {
     return <BoldTypography variant="subtitle2">{convertToTime(time)}</BoldTypography>;
   }
   return null;
 };
 
 const RouteDetails: React.FC<{ route: AirRouteWithSegment ,  }> = ( {route} ) => {

   const { t } = useTranslation();

   return(
   <>
     <Grid container spacing={4} justifyContent="flex-start" alignItems="baseline">
       <Grid item>
         <BoldTypography variant="subtitle2">{t("flight_number")}: </BoldTypography>
         <BoldTypography variant="subtitle2">{t("transport_type")}: </BoldTypography>
         <BoldTypography variant="subtitle2">{t("air_origin")}: </BoldTypography>
         <BoldTypography variant="subtitle2">{t("air_destination")}: </BoldTypography>
 
         {RenderTimeLabel({ datetime_local: route.departure_datetime_local , type: 1 } )}
         {RenderTimeLabel({ datetime_local: route.arrival_datetime_local , type: 2 } )}
         
         {RenderSegmentTime( { segmentTime: route.segmentTimes?.travelTime,   label: t("air_travel_time") } )}
         {RenderSegmentTime( { segmentTime: route.segmentTimes?.waitingTime,  label: t("air_waiting_time") } )}
         {RenderSegmentTime( { segmentTime: route.segmentTimes?.delayed_arrival_time, label: t("air_delay_arrival_time") } )}
         {RenderSegmentTime( { segmentTime:route.segmentTimes?.delayed_departure_time, label: t("air_delay_depart_time") })}
       </Grid>
 
       <Grid item>
         <Typography variant="subtitle2">{route.flight_number}</Typography>
         <Typography variant="subtitle2">{route.transport_type}</Typography>
         <Typography variant="subtitle2">{route.origin?.name}</Typography>
         <Typography variant="subtitle2">{route.destination?.name}</Typography>
 
         {RenderTime( { datetime_local:  route.departure_datetime_local } )}
         {RenderTime( { datetime_local: route.arrival_datetime_local })}
 
         {renderFormattedTime( { time: route.segmentTimes?.travelTime} ) }
         {renderFormattedTime( { time: route.segmentTimes?.waitingTime } )}
         {renderFormattedTime( { time: route.segmentTimes?.delayed_arrival_time } )}
         {renderFormattedTime( { time: route.segmentTimes?.delayed_departure_time })}
       </Grid>
     </Grid>
     <Divider variant="middle" sx={{ my: 2 }} />
   </>
   )
};


const RouteTotalTimes: React.FC<{travelTimes:TravelTimes}> = ({travelTimes}) =>{
    
    const {t} = useTranslation();
   
    return(
       <>
          <Grid container spacing={4} justifyContent="flex-start" alignItems="baseline">
              <Grid item>
                  <BoldTypography variant="subtitle2">{t("total_route_time")}: </BoldTypography>
                  <BoldTypography variant="subtitle2">{t("total_travel_time")}: </BoldTypography>
                  <BoldTypography variant="subtitle2">{t("total_waiting_time")}: </BoldTypography>
              </Grid>
              <Grid item>
                  { (travelTimes.totalRouteTime &&  travelTimes.totalRouteTime > 0) &&   <Typography variant="subtitle2">{convertToTime(travelTimes.totalRouteTime)}</Typography>  }
                  { (travelTimes.totalTravelTime &&  travelTimes.totalTravelTime > 0) &&   <Typography variant="subtitle2">{convertToTime(travelTimes.totalTravelTime)}</Typography>  }
                  { (travelTimes.totalWaitingTime &&  travelTimes.totalWaitingTime > 0) &&   <Typography variant="subtitle2">{convertToTime(travelTimes.totalWaitingTime)}</Typography>  }
              </Grid>
          </Grid>
          <Divider variant="middle" sx={{ my: 2 }} />
       </>
    )
}




const AirRouteTab: React.FC<{air_route: AirRoute[] | null}> = ({air_route}) => {
     
    const { t } = useTranslation();

    let travelTimes = calculateTravelTimes(air_route);
    
    let route_with_segment_times:AirRouteWithSegment[] = [];

    if(air_route && air_route.length > 0){

       route_with_segment_times = air_route.map((route) => {
               
         const matchedSegment = travelTimes.segmentTimes.find((segment) => segment.order_id === route.order_id)

         return {
            ...route,
            segmentTimes: matchedSegment || null, 
          };
             
          })
    }
     
     return(
        <> 
           {  travelTimes && (<RouteTotalTimes travelTimes={travelTimes}   />) }
           
           {route_with_segment_times && route_with_segment_times.length > 0 ?
          
                 route_with_segment_times.map((route)=>(
                    <Fragment key={route.order_id}>
                      <RouteDetails route={route}  />
                    </Fragment>     
              )) 
           :
           ( <PaperInfoNoData elevation={1} > {t("air_no_information_about_air_route")} </PaperInfoNoData>) }   
        </> 
     )
}


export default AirRouteTab;