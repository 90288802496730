
import { Tooltip } from 'react-leaflet';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import { AirRoute, TravelTimes } from '../../types/air_types';
import { SvgIcon , SvgIconProps } from '@mui/material';
import { ReactComponent as MyIcon } from '../../svg/delay-icon.svg';
import { convertToTime } from '../../helpers/airHelper';

const DelaySvgIcon = (props: SvgIconProps) => (
    <SvgIcon {...props}>
       <MyIcon />
    </SvgIcon>
  );



const MapPointTooltip: React.FC<{travel_times: TravelTimes, route: AirRoute , isLast: boolean }> =({travel_times,route , isLast}) =>{

  // Find the matched segment
  const matchedSegment = travel_times.segmentTimes.find(
    (item) => item.order_id === route.order_id
  );

  // If a matched segment is found, calculate the waiting time

    const waiting_time = matchedSegment ? convertToTime(matchedSegment.waitingTime) : null;
    const delay_departure_time = matchedSegment ? convertToTime(matchedSegment.delayed_departure_time) : null;
    const delay_arrival_time = matchedSegment ? convertToTime(matchedSegment.delayed_arrival_time) : null;
    const delayed_arrival_for_last_point =  matchedSegment ? convertToTime(matchedSegment.delayed_arrival_for_last_point) : null;

    if ((!waiting_time && !delay_departure_time && !delay_arrival_time) || (!delayed_arrival_for_last_point && isLast === true )) {
        return null; // If none of the times are available, return null and do not render the tooltip
      }

    
  // Return the Tooltip only if waiting_time exists
  return (
    <Tooltip permanent direction="center" offset={[0, -30]} className="point-tooltip">
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>

      {isLast === false ? (
            <>
                {waiting_time && (
                <div style={{ textAlign: 'left' }}>
                    <HourglassEmptyIcon sx={{ fontSize: 13 }} /> {waiting_time}
                </div>
                )}
                {delay_departure_time && (
                <div style={{ textAlign: 'left' }}>
                    <DelaySvgIcon sx={{ fontSize: 13 }} /> D : {delay_departure_time}
                </div>
                )}
                {delay_arrival_time && (
                <div style={{ textAlign: 'left' }}>
                    <DelaySvgIcon sx={{ fontSize: 13 }} /> A : {delay_arrival_time}
                </div>
                )}
            </>
            ) : (
            <>
                {delayed_arrival_for_last_point && (
                <div style={{ textAlign: 'left' }}>
                    <DelaySvgIcon sx={{ fontSize: 13 }} /> A : {delayed_arrival_for_last_point}
                </div>
                )}
            </>
            )}
      </div>
    </Tooltip>
  );
}

export default MapPointTooltip;