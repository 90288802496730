import React, { Fragment } from 'react';
import { RouteDataPoints,} from '../../types/types';
import { transformRoute , findPinIndex } from '../../helpers/mapHelper';
import { LatLngExpression} from 'leaflet';
import { Polyline} from 'react-leaflet';
import { useTheme } from '@mui/material/styles';



const MapRoute: React.FC<{route_data: RouteDataPoints }> = ({route_data}) =>{

    const theme = useTheme();

    let isPinFound = false;
      
     return(
        <>
           { route_data.route ?
             route_data.route.map((route,index) => {
                  
                let transformedRoute: LatLngExpression[] = transformRoute(route.path);
                let pinIndex;
                 
              
                if(route_data.pin !== null){
                   pinIndex = findPinIndex(transformedRoute, route_data.pin);
                }else{
                   pinIndex = null;
                }
  
                  if (pinIndex === -1  ) {
                      
                          if (isPinFound) {
                            
                            return (
                              <Polyline
                                key={index}
                                positions={transformedRoute}
                                color={theme.palette.primary.light}
                                dashArray="3 4"
                              />
                            );
                          } else {
                            
                            return (
                              <Polyline
                                key={index}
                                positions={transformedRoute}
                                color={theme.palette.secondary.main}
                              />
                            );
                          }
                     
                  }else if(pinIndex == null){ //if  pin  in object doesn't exist;
                      return (
                        <Polyline
                          key={index}
                          positions={transformedRoute}
                          color={theme.palette.primary.light}
                          dashArray="3 4"
                        />
                      );
                  }
                  else{
                    isPinFound = true;

                    if(pinIndex || pinIndex == 0){   //if pin index start from first element of array
                      let routeBeforePin = transformedRoute.slice(0, pinIndex + 1);
                      let routeAfterPin = transformedRoute.slice(pinIndex);


                      return (
                        <Fragment key={index}>
                          <Polyline positions={routeBeforePin} color = {theme.palette.secondary.main}  />
                          <Polyline positions={routeAfterPin} color={theme.palette.primary.light} dashArray="3 4" />
                        </Fragment>
                      );
                    }else{
                        
                       return  (
                          null
                       )
                          
                    }
                   
                  }   
             })
              : 
             ""
           }
        </>
     )
}


export default MapRoute;