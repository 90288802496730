import React from 'react';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import {Box, Typography } from '@mui/material'
import {HorizontalPointsBox, HorizontalStepper, HorizontalStepConnector} from '../../styles/TimeLineStyles';
import {SortedRoutePoint} from '../../types/types';
import HorizontalStepIcon from './HorizontalStepIcon';
import { useTranslation } from 'react-i18next';
import { AirLocation, AirMainRoute } from '../../types/air_types';
import { StepIconProps } from '@mui/material';


const HorizontalTimeLine: React.FC<{routePoints: SortedRoutePoint[] | AirMainRoute[]  , activeStep: number }> = ({routePoints , activeStep}) => {
   
  const { t } = useTranslation();

  return(
      <Box sx={{ width: '100%', pt:3 }} >
        <HorizontalPointsBox>
              {routePoints.map((route)=>{

                if('locationData' in route && route.locationData){
                      return route.locationData ? (
                        <Typography key={route.location}>
                            {route.locationData.name !== null ?  t(route.locationData.name) + "," : route.locationData?.locode } 
                            
                            { route.locationData?.locode && !route.locationData.name ? "" : route.locationData.country_code}
                        </Typography>
                      ) : (
                        <Typography key={route.location}>{t("hortimeline_location_data_not_available")}</Typography>
                      );
                  }

                
                if('iata_code' in route){
                    return route ? 
                    (
                       <Typography key={route.iata_code}>
                             {route?.iata_code  ?  route.iata_code + ' (' + route.nearest_city +')'  :  ''}
                       </Typography>
                    ) 
                    : 
                    (
                      <Typography >{t("hortimeline_location_data_not_available")}</Typography>
                    ) 
                }



              })}                     
        </HorizontalPointsBox>

        <HorizontalStepper activeStep={activeStep}  connector={<HorizontalStepConnector />  } >
            {routePoints.map((route,index) => (
                <Step key={index}>
                    <StepLabel 
                            StepIconComponent={
                              (props: StepIconProps) => (
                                  <HorizontalStepIcon {...props} sx={{ color: '#ff5722' }} />
                              )
                          }
                     >
                  </StepLabel>
                </Step> 
            ))}
        </HorizontalStepper>
      </Box>
  )

}


export default  HorizontalTimeLine;