
import { Location , RouteDataPoints,} from '../../types/types';
import { useState } from 'react';
import MapEventHandler from '../Map/MapEventHandler';
import MapPoints from '../Map/MapPoints';
import MapRoute from '../Map/MapRoute';
import PinMarker from '../Map/PinMarker';
import NotificationList from '../Notifications/NotificationList';

const SeaMapViewer: React.FC<{locations: Location[] | null , route_data: RouteDataPoints | null}> = ({locations, route_data}) => {
       
    const [radius, setRadius] = useState<number>(5);
    const notifications = [];
     
    let locationsWithoutCoordinates: Location[] | null = [] ;

    if(locations !== null){
        locationsWithoutCoordinates   = locations.filter(location => location.lat == null || location.lng == null);
    }

          //Notifications
          if (!route_data?.pin) {
            notifications.push("map_current_localization_is_not_available");
          }
    
          if (!route_data?.route || route_data.route.length == 0) {
            notifications.push("map_route_data_is_not_available");
          }
    
          if(locationsWithoutCoordinates.length > 0){
            notifications.push("map_incomplete_location_data");
          }
          
 return (<>
          
          <MapEventHandler setRadius={setRadius} /> 
            <MapPoints locations={locations ?? []}  /> 

         
                {route_data?.route ? (
                    <>
                    <MapRoute route_data={route_data} />  

                    {route_data.pin ?  
                        <PinMarker pin={route_data.pin} radius={radius} /> 
                        : 
                        <NotificationList messages={notifications} />
                    }
                    </>
                ) : 
                    <NotificationList messages={notifications} />
                }   
   
    </>)

}


export default SeaMapViewer;