import { AirData , AirMainRoute, AirMetaData} from "../../types/air_types";
import { useTranslation } from 'react-i18next';
import { InfoSideMainBox,InfoSidePaper,InfoPaper,InfoSideBox,ChipStatus } from "../../styles/InfoSideBarStyles";
import {Box, Typography} from '@mui/material';
import HorizontalTimeLine from "../HorizontalTimeLine/HorizontalTimeLine";
import { getActiveAirRouteStep, getAirMainRoute } from "../../helpers/airHelper";
import AirRouteInfo from "./AirRouteInfo";
import AirTabs from "./AirTabs";
import InfoSideBarFooter from "../InfoSideBarFooter";
import { MonitoringStatuses } from "../../types/types";


const AirInfoSideBar: React.FC<{air_data: AirData , metadata: AirMetaData | null , language: string | null, monitoring_status: MonitoringStatuses}> = ({air_data, metadata, language , monitoring_status})=>{

    const { t } = useTranslation();
    const {status, origin , destination ,departure_datetime_local,arrival_datetime_local ,  piece ,weight ,routes,events }  = air_data;

   let air_main_route: AirMainRoute[] | null = getAirMainRoute(origin,destination,departure_datetime_local,arrival_datetime_local);
   let active_step = getActiveAirRouteStep(air_main_route);
     
    return(
       <InfoSideMainBox>
            <InfoSidePaper>
                <InfoPaper elevation={2} >
                     <Typography variant='h6' >{metadata?.request_parameters.number}</Typography> 
                </InfoPaper> 
                  
                <Box sx={{paddingTop:3}}>
                        <InfoSideBox>
                            <Box>
                                <Typography variant="subtitle2" sx={{ mr: 1 }}> {metadata?.request_parameters.number} </Typography> 
                            </Box>
                            <Box display="flex">
                                <Typography variant="subtitle2" sx={{ mr: 2 }}/>
                                <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>{piece} {t("pieces")} / {weight} kg </Typography> 
                                    {status !== null && <ChipStatus  size="small"  label={t(status)} />  }                                  
                            </Box>
                     </InfoSideBox>   
                </Box>
                <HorizontalTimeLine  routePoints={air_main_route} activeStep={active_step} />
                <AirRouteInfo departure={departure_datetime_local}  arrival={arrival_datetime_local} airline={metadata?.airline ?? null} />
                 

                <AirTabs air_events={events} air_routes={routes}  /> 

                <InfoSideBarFooter language={language} monitoring_status = {monitoring_status} />
        

            </InfoSidePaper> 
       </InfoSideMainBox>
    )
}

export default AirInfoSideBar;