import {CssBaseline} from '@mui/material'
import { useState, useEffect } from 'react';
import { getParamsFromUrl,handleTokenRefreshAndTracking,} from '../services/authService'
import {Loader,LoaderBox} from '../styles/InfoSideBarStyles';
import GridItems from '../components/GridItems';
import { TrackItem, Credentials, MonitoringStatuses, MonitoringType, validMonitoringTypes} from '../types/types';
import { AirTrackItem } from '../types/air_types';
import { setMonitoringStatus } from '../helpers/infoSideBarHelper';
import ErrorBox  from '../components/ErrorBox';
import i18n from 'i18next';
// import { trackItem3, trackItem2 ,airTrackItem1 } from '../services/testdata';
import { ThemeProvider} from '@mui/material';
import { useTransportTypeContext } from '../context/TransportTypeContext';
import { getAppTheme } from '../helpers/themeHelper';


export default function Home(){
  const [monitoringStatusFromTms, setMonitoringStatusFromTms] = useState<MonitoringStatuses>('UNKN');
  const [trackItem , setTrackItem] = useState<TrackItem | AirTrackItem |  null>(null);
  const [error , setError] = useState('');
  const [loading , setLoading] = useState(true);
  const [language, setLanguage] = useState<string | null>('pl');

  const {type , setType} = useTransportTypeContext();

  
  useEffect(() => {
     
    getParamsFromUrl().then((urlParams)=>{

      const {session_id , number, instance_name ,language , reference_id , monitoring_status, monitoring_type} = urlParams;

      const instance = instance_name ? instance_name.replace(/-/g, '_') : '';

      const user = process.env[`REACT_APP_INSTANCE_${instance}_USER_NAME`];
      const password = process.env[`REACT_APP_INSTANCE_${instance}_PASSWORD`];

 
           if (!user || !password) {
             setError('auth_invalid_credentials');
             setLoading(false);
             return;
           }
 
         if(user && password && session_id && number){
 
           let credentials: Credentials ={
              user: user,
              password: password,
              session_id: session_id,
              number: number,
              language: language,
              reference_id: reference_id,
            }

      
            if (validMonitoringTypes.includes(monitoring_type as MonitoringType)) {
             setType(monitoring_type as MonitoringType);
            }

            i18n.changeLanguage(language ?? 'pl');

           setMonitoringStatusFromTms(setMonitoringStatus(monitoring_status));
           
           handleTokenRefreshAndTracking( credentials ,setTrackItem, setLoading , setError, setLanguage, monitoring_type); 
         }
          
    })
    .catch((error)=>{
        setError('auth_error_fetching_url_params');
        setLoading(false);
    }) 
    
      }, [setType, type]);
   
   
    const appTheme = getAppTheme(type);

 return(

  <ThemeProvider theme={appTheme}>
    {loading &&  
        <LoaderBox>
            <Loader/> 
        </LoaderBox>
    }

    {error && <ErrorBox error={error}  />}

    {!loading && !error && trackItem && (
        <>
        <CssBaseline />
        {type && <GridItems 
                       trackItem={trackItem} 
                       language={language} 
                       monitoring_status={monitoringStatusFromTms} 
                  /> 
         } 
        </>
    )}
   </ThemeProvider>
 )

}
