import { StepIconProps } from '@mui/material/StepIcon';
import TripOriginIcon from '@mui/icons-material/TripOrigin';
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';
import { TimeLineStepIcon } from '../../styles/TimeLineStyles';
import { useTheme } from '@mui/material/styles';


function HorizontalStepIcon(props: StepIconProps) {

    const theme = useTheme();
    const { active, completed, className } = props;

    let iconColor: string;
  
     if(!active && !completed ){
           
      iconColor = theme.palette.primary.light;
     }else{

      iconColor = theme.palette.secondary.main;
     }
     
  
    return (
      <TimeLineStepIcon ownerState={{ completed, active }} className={className}>
         { active ?   <ArrowCircleRightOutlinedIcon fontSize="small" />  :  <TripOriginIcon  sx={{fontSize: 18, color: iconColor}}  /> }
      </TimeLineStepIcon>
    );
  }

export default HorizontalStepIcon;