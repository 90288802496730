import {Marker} from 'react-leaflet';
import { useTheme } from '@mui/material/styles';
import { LatLngExpression} from 'leaflet';
import { CustomMapMarker } from '../../styles/MapViewerStyles';
import { transformLocations} from '../../helpers/mapHelper';
import { Location} from '../../types/types';



const MapPoints: React.FC<{locations: Location[] }> = ({locations}) =>{

    const theme = useTheme();
    
    let points: LatLngExpression[] = [];
    if (locations) {
      points = transformLocations(locations);
    }

     return(
        <>
        {points.map((point, index) => (
          <Marker
            key={`active-${index}`}
            position={point}
            icon={CustomMapMarker(theme.palette.secondary.dark)}
          />
        ))}
      </>
     )

}


export default MapPoints;
