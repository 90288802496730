import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { useState } from 'react';
import CustomTabPanel from '../TabPanel/CustomTabPanel';
import { useTranslation } from 'react-i18next';
import { AirEvents, AirRoutes } from '../../types/air_types';
import AirVerticalTimeLine from '../Air/AirVerticalTimeLine';
import AirRouteTab from './AirRouteTab';
import { a11yProps } from '../../helpers/infoSideBarHelper';



const AirTabs: React.FC<{air_events: AirEvents| null , air_routes: AirRoutes | null}> = ({air_events, air_routes}) =>{
      
      const { t } = useTranslation();
      const [value,setValue]  = useState<number>(0);

      const handleChange = (event: React.SyntheticEvent , newValue: number) =>{
        setValue(newValue);
    }
      
    return(
        <Box sx={{ width: '100%' , paddingTop:2}} >
        <Box sx={{borderBottom: 1, borderColor: 'divider'}}> 
            <Tabs value={value} onChange={handleChange} aria-label="basic-tab-example"   
                textColor = 'secondary' 
                indicatorColor='secondary'
                >
            <Tab label= {t("tab_air_history")} {...a11yProps(0)}  sx={{ fontSize: 12 }}/>
            <Tab label= {t("tab_air_route")} {...a11yProps(1)} sx={{ fontSize: 12 }} />
            </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0} >
             {  
                air_events ? 
                 <AirVerticalTimeLine  air_events = {air_events} />
                 : 
                 <>
                    {t("No route history")};
                 </>
             }
             
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1} >
             <AirRouteTab air_route ={air_routes}  />
        </CustomTabPanel>
        
    </Box>
   )

}


export default AirTabs;
