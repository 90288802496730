import { MapContainer , TileLayer} from 'react-leaflet'
import React from 'react';
import { useState } from 'react';
import { Location , RouteDataPoints,} from '../../types/types';
import MapSelector from './MapSelector';
import { useTransportTypeContext } from '../../context/TransportTypeContext';
import { AirRoute } from '../../types/air_types';
import SeaMapViewer from '../Sea/SeaMapViewer';
import AirMapViewer from '../Air/AirMapViewer';

const mapTiles = [
  { key: 'osm', url: "https://{s}.tile.osm.org/{z}/{x}/{y}.png" },
  { key: 'carto', url: "https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png" },
]

const isRouteDataPoints = (data: any): data is RouteDataPoints => {
  return (
      data && !Array.isArray(data) &&  'pin' in data && 'ais' in data
  );
};

const isAirRoutes = (data: any): data is AirRoute[] => {
   
    return(
        Array.isArray(data) &&
        data.every((item:any) => 'order_id' in item && 'flight_number' in item)
    )
}


const MapViewer: React.FC<{locations: Location[] | null , route_data: RouteDataPoints | AirRoute[] |  null}> = ({locations, route_data}) => {
     
    const defaultTile = mapTiles.find(tile => tile.key === 'carto')?.url || '';
    const [tile , setTile] = useState(defaultTile);
    const {type , setType} = useTransportTypeContext();

    return(
      <MapContainer center={[52.0693,19.4803]} zoom={3} style={{ height: '100%', width: '100%' ,display: 'flex'}} attributionControl={false}  zoomControl={true}>
            <TileLayer 
                minZoom={2}
                url={tile}
               attribution=''// '&copy; <a href="<http://osm.org/copyright>">OpenStreetMap</a> contributors' //turn off contribution
              /> 

        { type === 'AIR' && (<AirMapViewer  air_routes = {isAirRoutes(route_data) ? route_data : null }  />) }
        { type === 'SEA' && (<SeaMapViewer locations = {locations}  route_data = {isRouteDataPoints(route_data) ? route_data : null}/>) }
        
       <MapSelector setTile = {setTile} mapTiles={mapTiles}/>

    </MapContainer>
    )
}

export default MapViewer;