import { createTheme } from '@mui/material/styles';
import { lightBlue, red, grey } from '@mui/material/colors';

export function getAppTheme(type: 'AIR' | 'SEA' | null) {
   
    const color_palette = type === 'SEA' ? {
      main: lightBlue[700],
      light: lightBlue[100],
      dark: lightBlue[900],
    } : {
      main: red[900],
      light: red[100],
      dark: red[900],
    };
  
    
    return createTheme({
      palette: {
        primary: {
          main: grey[900],
          light: grey[500],
        },
        secondary: color_palette,
      },
      typography: {
        fontFamily: '"Roboto", sans-serif',
      },
    });
  }