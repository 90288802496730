import { AirEvents, AirTimeLineEvent } from "../../types/air_types";
import { findLastActualAirEvent, getSortedAirEvents } from "../../helpers/airHelper";
import { VerticalStepper } from "../../styles/TimeLineStyles";
import { PaperInfoNoData } from "../../styles/InfoSideBarStyles";
import { useTranslation } from "react-i18next";
import { VerticalStepConnector,VerticalStepConnectorIcon} from '../../styles/TimeLineStyles'; 
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { Typography } from "@mui/material";
import VerticalLineStepIcon from '../VerticalTimeLine/VerticalStepIcon';
import VerticalTimeLineEvent from '../VerticalTimeLine/VerticalTimelineEvent';
import { Fragment } from "react/jsx-runtime";



const AirVerticalTimeLine: React.FC<{air_events: AirEvents}>  =({air_events})=>{
     
    const { t } = useTranslation();

    let orderedEvents = air_events ? getSortedAirEvents(air_events) : null;

    let activeStep: number;
    let activeEvent:AirTimeLineEvent ;

    //Find last active location
    let index = orderedEvents && orderedEvents.findIndex(item=> item.events.length > 0 && item.events[0].active === 0 );

    let activeLocationIndex;

    if(index !== null ){
         if(index !== -1){
            activeStep = index-1;
            activeLocationIndex = index -1;
         }else{
             if(orderedEvents !== null){
                activeStep = orderedEvents.length - 1;
                activeLocationIndex = orderedEvents.length - 1;  
             }
             
         }
    }

    if(activeLocationIndex && activeLocationIndex !== -1 && orderedEvents !== null){
         
        let lastActualEvent  = findLastActualAirEvent(orderedEvents[activeLocationIndex].events);

        if(lastActualEvent){
            activeEvent = lastActualEvent;
        }

    }


    return(
    <>
           {orderedEvents && orderedEvents.length > 0 ?
             
              <VerticalStepper orientation="vertical" connector={<VerticalStepConnector />} activeStep={1} >
                 { 
                    orderedEvents.map((event,index) =>(
                       <Step key={`${event.location.iata_code}`}
                            active = {index == activeStep}
                            completed = { index < activeStep }
                            disabled = {index > activeStep}>
                    
                          <StepLabel key={`empty-${index}-${event.location.lat}`} StepIconComponent={ (props) => <VerticalLineStepIcon custom_icon="location"  {...props} />} sx={{paddingBottom:6 }}>
                             <Typography variant="subtitle2" sx={{color: 'secondary.main',}}> 
                                  {event.location?.iata_code !== null ? event.location?.iata_code   :  ''}

                                  {event.location?.country &&  " (" + event.location?.country + ")"  }
                            </Typography>
                          </StepLabel>  
                          
                          <StepLabel StepIconComponent={(props) => ( <VerticalStepConnectorIcon  active={props.completed || props.active ? 1: 0} /> )} />
                           
                            {
                                event.events.map((event,index)=>(
                                     <Fragment key={index} >
                                      {event == activeEvent ?
                                        <StepLabel key={`active-${index}`} StepIconComponent={(props) => <VerticalLineStepIcon custom_icon="event"  {...props} />}  >
                                             <VerticalTimeLineEvent event={event}  /> 
                                         </StepLabel>
                                         :
                                      <StepLabel key={`inactive-${index}`}  StepIconComponent={ () => <VerticalStepConnectorIcon active={event.active ? +event.active : 0}  /> } >
                                          <VerticalTimeLineEvent event={event}  />   
                                      </StepLabel> 
                                      }

                                     </Fragment>
                                ))
                            }

                       </Step>
                    ))

                }
              </VerticalStepper>
              :
              <PaperInfoNoData>{t("vertimeline_no_information_about_route")}</PaperInfoNoData>
           
        }
    </>
      
    )
      
}


export default AirVerticalTimeLine;