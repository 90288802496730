import React , {useState} from 'react';
import { Box, Menu, MenuItem } from '@mui/material';
import LayersIcon from '@mui/icons-material/Layers';
import CheckIcon from '@mui/icons-material/Check'; 
import { MapSelectorProps} from '../../types/types';
import { MapSelectorBox } from '../../styles/MapViewerStyles';




const MapSelector :React.FC<MapSelectorProps> = ({ setTile , mapTiles}) =>{
     
    const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
    const [selectedTile, setSelectedTile] = useState<string>('carto');


    const handleOpenMenu = (event: React.MouseEvent<HTMLDivElement>) => {
        setAnchorEl(event.currentTarget);
    };
    
    const handleCloseMenu = () => {
        setAnchorEl(null);
      };
    
    const handleMenuItemClick = (selectedTile: string) => {
         
            let tile = mapTiles.find(tile => tile.key === selectedTile)?.url || '';
            let selTile = selectedTile;
            setSelectedTile(prevState => prevState = selTile);
            setTile(tile);
           
            
            handleCloseMenu();         
      };


      return (
        <Box>
          <MapSelectorBox onClick={handleOpenMenu} >
             <LayersIcon sx={{ fontSize: 30 }} />
        </MapSelectorBox>

          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleCloseMenu}
          >
            {mapTiles.map(({ key }) => (
              <MenuItem key={key} onClick={() => handleMenuItemClick(key)}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  {key}
                  {selectedTile === key && (
                     <CheckIcon  style={{ marginRight: 8, color: 'green' }}  />
                  )}
                </div>
              </MenuItem>
            ))}
          </Menu>
        </Box>
      );
}

export default MapSelector;