import React, {createContext , useContext , useState, ReactNode} from 'react';


interface TransportTypeContextType{
    type: 'AIR' | 'SEA' | null
    setType: (type: 'AIR' | 'SEA' | null) => void;
}

const TransportTypeContext = createContext<TransportTypeContextType | undefined>(undefined);

const TransportTypeProvider = ({children} : {children: ReactNode} ) =>{
     
      const [type , setType] = useState< 'AIR' | 'SEA' | null>(null);

       return(
           <TransportTypeContext.Provider value={{type , setType}}>
               {children}
           </TransportTypeContext.Provider>
       )

}


const useTransportTypeContext = () =>{
     const context = useContext(TransportTypeContext);

     if(!context){
         throw new Error("useAppContext must be used within an AppProvider")
     }

     return context;
}

export{
    TransportTypeProvider,
    useTransportTypeContext,
} 