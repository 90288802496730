import Box from '@mui/material/Box';

interface TabPanelProps{
    children?: React.ReactNode;
    index: number;
    value: number;
}
    
const CustomTabPanel :React.FC<TabPanelProps> = (props) => {
    const {children, value , index, ...other} = props;
    
    return(
        <div
          role="tabpanel"
          hidden={ value !== index}
          id ={`simple-tabpanel -${index}`}
          aria-labelledby={`simple-tab-${index}`}
          {...other}
         >
          {value == index &&(
             <Box sx={{ height: '350px', overflowY: 'auto',  padding: 3,}} >
                {children}
             </Box>
          )}   
        </div>
    )
}


export default CustomTabPanel;
