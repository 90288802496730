import {Box, Grid } from '@mui/material';

interface SharedGridProps{
     sideBar: React.ReactNode,
     mapViewer: React.ReactNode
}


const SharedGrid: React.FC<SharedGridProps> = ({sideBar, mapViewer}) =>{
       
      return(
        <Box sx={{display: 'flex'}}  >
        <Grid container alignItems="stretch" style={{ height: '100vh' }} >
           <Grid item xs={12} md={4.5} >
                 {sideBar}
           </Grid>
           <Grid item xs={12} md={7.5} >
              <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
              <Box sx={{ flexGrow: 1, marginTop: 1, marginBottom: 1, height:"100vh" }}>
                 {mapViewer}
              </Box>
         </Box>
           </Grid> 
        </Grid>
     </Box>  
      ) 


}

export default SharedGrid;